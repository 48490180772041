<template>
    <div class="content" :id="contentId"></div>
</template>

<script>
    import * as echarts from "echarts";
    export default {
        data() {
            return {
                mychart: null,
                chart: {
                    title: {
                        text: ''
                    },
                    // color: ["#7471FE", "#00F3FF", "#0191F2", "#FEDE65", "#189ABC"],
                    tooltip: {
                        trigger: "axis",
                        axisPointer: {
                            type: "cross",
                        },
                        textStyle: {
                            fontSize: 14,
                        },
                        formatter: function (params) {
                            console.log(params)
                            let relVal = null
                            for (let i = 0; i < params.length; i++) {
                                if (params[i].value) {
                                    relVal += "<br/>" +
                                        params[i].axisValue+
                                        ":  " +
                                        params[i].value +
                                        "万吨;";
                                }else{
                                    relVal += "<br/>" +
                                        params[i].axisValue + 
                                        ":  " +
                                        '0' +
                                        "万吨;";
                                }

                            }
                            return relVal.slice(9)
                        }
                    },
                    legend: {
                        itemHeight: 11,
                        itemWidth: 11,
                        icon: "roundRect",
                        borderRadius: [5, 5, 5, 5],
                        right: 30,
                        top: 10,
                    },
                    grid: {
                        left: "60px",
                        right: "30px",
                        top: "60px",
                        bottom: "30px",
                    },
                    xAxis: [
                        {
                            type: "category",
                            axisTick: {
                                show: false,
                            },
                            axisLine: {
                                lineStyle: {
                                    width: 0.75,
                                    type: "dashed",
                                },
                            },
                            axisLabel: {
                                padding: [4, 10, 0, 20],
                            },
                            data: [],
                        },
                    ],
                    yAxis: {
                        type: "value",
                        nameTextStyle: {
                            padding: [6, 0, 0, 8], // 四个数字分别为上右下左与原位置距离
                        },
                        splitLine: {
                            show: true,
                            lineStyle: {
                                width: 0.75,
                                type: "dotted",
                            },
                        },
                        axisLine: {
                            show: true,
                            lineStyle: {
                                // 属性lineStyle控制线条样式
                                // color: [
                                //     [0.2, "#c23531"],
                                //     [0.8, "#63869e"],
                                //     [1, "#91c7ae"],
                                // ],
                            },
                        },
                        axisLabel: {
                        },
                    },
                    series: [],
                },
            };
        },
        created() { },
        mounted() {
            // console.log(this.data, '----1---')
            // this.formatData(this.data)
        },
        beforeDestroy() {
            // 在组件销毁之前清除自定义事件
            this.$bus.$off("onresize");
        },
        props: {
            data: Object | Array,
            contentId: String,
        },
        watch: {
            data: {
                handler(newVal) {
                    console.log(newVal)
                    if (newVal) {
                        this.formatData(JSON.parse(JSON.stringify(newVal)));
                    }
                },
                deep: true
            },
            contentId(newVal) {
                this.contentId = newVal;
            },
        },
        methods: {
            formatData(data) {
                this.chart.xAxis[0].data = data.items[0].values.map(
                    (item) => item.lateralAxis
                );
                console.log(data)
                this.chart.yAxis.name = data.unit;
                // this.chart.title.text = data.title
                // if (data.ext.color && data.ext.color.length > 0) {
                //   this.chart.color = data.ext.color;
                // }
                this.chart.series = data.items.map((item) => {
                    return {
                        name: item.title,
                        type: "bar",
                        label: {
                            show: true,
                            position: "top",
                        },
                        data: item.values.map((item) =>  item.value == 0 ? '' : item.value),
                    };
                });
                this.init();
            },
            init() {
                this.mychart = echarts.init(document.getElementById(this.contentId));
                this.mychart.setOption(this.chart);
                this.$bus.$on("onresize", () => {
                    this.mychart.resize();
                });
            },
        },
    };
</script>

<style scoped lang="scss">
    .content {
        width: 100%;
        /* height: calc(100% - 30px); */
        height: 400px;
    }
</style>